<template>
  <div class="contain" :style="{'padding-bottom' : bottomState ? '81px' : '20px'}" v-title data-title="商品详情" v-if="proData">
    <div class="topswipe">
      <van-swipe :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item, index) in proData.imageList" :key="index" >
          <van-image width="100%" height="100%" :src="img_url + item.imgUrl" @click="onLargeImg(item.imgUrl)"/>
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="ta">
      <div class="ta-2">[{{proData.proBrandName}}]{{proData.proName}}</div>
      <div class="ta-3 coal">
        <span v-if="proData.proAlias">[{{proData.proAlias}}]</span>
        <span class="coe4" v-if="proDataUnit.assistName"> 1{{proDataUnit.unitName}}≈{{proDataUnit.assistRatio}}{{proDataUnit.assistName}}</span>
      </div>
      <div class="ta-1">
        <span class="coe4 bold">
          <span>￥</span><span class="fz20">{{proDataUnit.onlinePrice}}</span>
        </span>
        <span class="cob3" style="text-decoration: line-through;margin-left: 4px;">￥{{proDataUnit.marketPrice}}</span>
        <span style="margin-left: 10px;">
          <span v-if="proDataUnit.proRuleNum1">满{{proDataUnit.proRuleNum1}}单价￥{{proDataUnit.proRuleUnitPrice1}} </span>
          <span v-if="proDataUnit.proRuleNum2">，满{{proDataUnit.proRuleNum2}}单价￥{{proDataUnit.proRuleUnitPrice2}}</span>
        </span>
      </div>
    </div>
    <div class="tb">
      <div class="tb-1">选择规格/单位</div>
      <div class="tb-2">
        <div class="item2" :class="idx === index? 'active' : '' " v-for="(item, index) in proData.productDetailSpecifications"
             :key="index" @click="selectSpec(index)">
          <div class="flex" style="justify-content: center;align-items: center;margin-right: 6px;">
            <van-image width="40px" height="40px" v-if="item.specificationImage" :src="img_url + item.specificationImage"/>
            <van-image width="40px" height="40px" v-else :src="img_url + defalutImg" />
          </div>
          <div style="word-break: break-all;">{{item.specification}}</div>
        </div>
      </div>
      <div class="tb-3">
        <div class="item3" :class="idx2 === index2? 'active' : '' " v-for="(item2, index2) in proData.productDetailSpecifications[idx].proDetailUnits"
             :key="index2" @click="selectUnit(index2)">
          {{item2.unitName}}
        </div>
      </div>
    </div>
    <div class="jf flex">
      <div class="fz15">库存：{{ proDataUnit.inventory }}</div>
      <van-button style="margin-left: 30px" type="danger" size="mini" v-if="proDataUnit.inventory <= 0" @click="shitchExped">催货</van-button>
    </div>
    <div class="jf" v-if="proDataUnit.dptCumPro && proDataUnit.dptCumPro.num && proDataUnit.dptCumPro.cum">
      <img src="../../assets/img/jifen.png" style="width: 20px;height: 19px;vertical-align: middle">
      <span style="vertical-align: middle;margin-left: 6px">每满{{proDataUnit.dptCumPro.num}}件，赠送{{proDataUnit.dptCumPro.cum}}积分</span>
    </div>
    <div class="tc" v-if="proDataUnit.rules">
      <div class="tc-1 bold" style="width: 10%">
        <span>优惠</span>
      </div>
      <div class="tc-1">
        <div class="tci" v-for="(item, index) in proDataUnit.rules" :key="index">
          <span class="item">{{item}}</span>
        </div>
      </div>
    </div>
    <div class="td">
      <div class="td-1">商品详情</div>
      <div class="td-2">
        <div class="td2-item">
          <div class="iteml">厂家：</div>
          <div class="itemr">{{proData.proManufacturersName}}</div>
        </div>
        <div class="td2-item">
          <div class="iteml">品牌：</div>
          <div class="itemr">{{proData.proBrandName}}</div>
        </div>
        <div v-for="(item, index) in proData.proAttributeList" :key="index">
          <div class="td2-item" v-if="item.attributeValue">
            <div class="iteml">{{item.attributeName}}：</div>
            <div class="itemr">{{item.attributeValue}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="te" v-html="proData.proContext"></div>
<!--    type1-->
<!--    <div class="bottom">-->
<!--      <div class="bottom-item" style="padding-left: 20px;">-->
<!--        <van-icon name="orders-o" size="6vw" @click="addOften"/>-->
<!--        <div>加入常购</div>-->
<!--      </div>-->
<!--      <div class="bottom-item">-->
<!--        <van-icon name="shopping-cart-o" size="6vw" @click="toShopCart"/>-->
<!--        <div>购物车</div>-->
<!--      </div>-->
<!--      <div class="bottom-item2 coff" style="background-color: #E40404;" @click="showPop">-->
<!--        加入购物车-->
<!--      </div>-->
<!--    </div>-->
<!--    type2-->
    <div class="bottom" v-if="bottomState">
      <div class="bottom-item">
        <van-icon name="records" size="6vw" color="#666" @click="addOften"/>
        <div style="color: #666">加入常购</div>
      </div>
      <div class="bottom-item" style="">
        <van-icon name="shopping-cart-o" size="6vw" color="#666" :info="cartNum" @click="toShopCart"/>
        <div style="color: #666">购物车</div>
      </div>
      <div class="bottom-item3 coff" >
        <div class="fz16" style="background-color: #E40404;width: 100%;border-radius: 30px;text-align: center" @click="showPop">加入购物车</div>
      </div>
    </div>
    <van-popup class="popup" v-model="popStatus" closeable position="bottom" @close="closePop">
      <div class="goodsitem-contain">
        <div class="a">
<!--          <div class="l">-->
<!--            <van-image width="100px" height="100px" v-if="proData.productDetailSpecifications[idx].specificationImage" :src="img_url + proData.productDetailSpecifications[idx].specificationImage" />-->
<!--            <van-image width="100px" height="100px" v-else :src="img_url + defalutImg" />-->
<!--          </div>-->
<!--          <div class="r">-->
<!--            <div class="bold fz15">{{proData.proName}}</div>-->
<!--            <div>-->
<!--              <span class="fz18 bold coe4">￥{{proDataUnit.onlinePrice}}</span>-->
<!--              <span class="cob3" style="text-decoration: line-through;margin-left: 5px;">￥{{proDataUnit.marketPrice}}</span>-->
<!--            </div>-->
<!--            <div class="coe4 mt5 fz12" :class="proDataUnit.rules ? '' : 'height15' ">-->
<!--              <span v-for="(item, index) in proDataUnit.rules"-->
<!--                    :key="index" style="border: 1px solid #E40404;border-radius: 3px;padding: 2px 4px;line-height: 21px;">{{item}}</span>-->
<!--            </div>-->
<!--            <div class="mt5">-->
<!--              已选“{{proData.productDetailSpecifications[idx].specification}}/{{proDataUnit.unitName}}”-->
<!--            </div>-->
<!--          </div>-->
          <div class="l">
            <van-image width="100px" height="100px" v-if="proData.productDetailSpecifications[idx].specificationImage" :src="img_url + proData.productDetailSpecifications[idx].specificationImage" />
            <van-image width="100px" height="100px" v-else :src="img_url + defalutImg" />
          </div>
          <div class="r">
            <div class="fz15 bold">[{{proData.proBrandName}}]{{proData.proName}}</div>
            <div>
              <span class="coal" v-if="proData.proAlias">[{{proData.proAlias}}]</span>
              <span class="cob3">{{proDataUnit.unitName}}</span>
              <span class="coe4" v-if="proDataUnit.assistName"> 1{{proDataUnit.unitName}}≈{{proDataUnit.assistRatio}}{{proDataUnit.assistName}}</span>
            </div>
            <div class="mt5" style="width: 250px;height: 15px;">
              <span v-if="proDataUnit.rules">
                <span class="youhui" v-for="(item, index) in proDataUnit.rules" :key="index">{{item}}</span>
              </span>
            </div>
            <div class="mt5">
              <span class="fz18 bold" style="color: #E40404">￥{{proDataUnit.onlinePrice}}</span>
              <span class="cob3" style="text-decoration: line-through;margin-left: 5px;">￥{{proDataUnit.marketPrice}}</span>
            </div>
            <div class="jf2" v-if="proDataUnit.dptCumPro && proDataUnit.dptCumPro.num && proDataUnit.dptCumPro.cum">
              <img src="../../assets/img/jifen.png" style="width: 16px;height: 15px;vertical-align: middle">
              <span style="vertical-align: middle;">每满{{proDataUnit.dptCumPro.num}}件，赠送{{proDataUnit.dptCumPro.cum}}积分</span>
            </div>
          </div>
        </div>
        <div class="b">
          <div class="bt">
            <div>产品规格：</div>
            <div class="flex mt5 wrap">
              <div class="tag" :class="idx === index? 'selecttag' : '' " v-for="(item, index) in proData.productDetailSpecifications"
                   :key="index" @click="selectSpec(index)">
                {{item.specification}}
              </div>
            </div>
          </div>
          <div class="bt mt10">
            <div>计量单位：</div>
            <div class="flex wrap mt5">
              <div class="tag" :class="idx2 === index2? 'selecttag' : '' " v-for="(item2, index2) in proData.productDetailSpecifications[idx].proDetailUnits"
                   :key="index2" @click="selectUnit(index2)">
                {{item2.unitName}}
              </div>
            </div>
          </div>
          <div class="bs">
            <div style="flex: 1">购买数量：</div>
            <div class="flex" style="width: 40%;align-items: center;justify-content: flex-end">
<!--              <img style="width: 25px;height: 25px" src="../../assets/img/reduce.png" @click="reduceNum" />-->
<!--              <input class="bsinput" type="number" placeholder="0" v-model="inputNum" @blur="onInputNum"/>-->
<!--              <img style="width: 25px;height: 25px" src="../../assets/img/add.png" @click="addNum" />-->
              <van-stepper v-model="inputNum" input-width="60px" button-size="32px" :value="1" :min="1" :decimal-length="proDataUnit.isInBulk > 0 ? 2 : 0"/>
            </div>
          </div>
          <div class="bd" @click="addCart">
            <van-button class="btn" block color="#E40404" round>加入购物车</van-button>
          </div>
          <div class="be" @click="addCartNow">
            <van-button class="btn" block round>立即结算</van-button>
          </div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="largeImg">
      <div style="width: 100vw">
        <van-image width="100%" height="100%" :src="img_url + largeImgUrl" @click="closeImg"/>
      </div>
    </van-popup>
    <van-popup v-model="establishBusiness">
      <div style="width: 60vw;padding: 10px;text-align: center">
        <div>此商品需要关联店铺之后才能操作</div>
        <div style="margin-top: 10px;">
          <van-button type="danger" size="small" @click="onEstab">确认关联</van-button>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="expeditingStatus">
      <div style="width: 60vw;padding: 10px;text-align: center">
        <div>请输入催货数量</div>
        <div style="margin-top: 20px;">
          <van-stepper min="1" v-model="expeditingNum" theme="round"/>
        </div>
        <div>
          <van-button style="margin-top: 20px;width: 100px" type="danger" size="small" @click="sendExped">确认</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { versions } from '@/common/util'
import tt from '@/assets/js/ttjssdk'
import wx from 'weixin-js-sdk'
import {
  productShopDetail,
  addOften,
  addShoppingCat,
  establishBusinessRelationship,
  shoppingCartIcon,
  insertShopProUrge
} from '@/common/api'

export default {
  name: 'goodsDetail',
  components: {
  },
  data () {
    return {
      // 请求商品详情
      proId: '',
      shopId: '',
      sessionID: '',
      mid: '',
      inputNum: 1,
      popStatus: false,
      proData: {},
      proDataUnit: {},
      largeImg: false,
      largeImgUrl: '',
      // 关联状态
      establishBusiness: false,
      // 催货
      expeditingStatus: false,
      expeditingNum: 1,
      // 外面商品信息
      idx: 0,
      idx2: 0,
      img_url: process.env.VUE_APP_IMG_URL,
      data: '',
      defalutImg: '',
      cartNum: '',
      isInBulk: 0,
      bottomState: true
    }
  },
  mounted () {
    // console.log('h5接收', this.$route.query)
    window.getFromAndroid = this.getFromAndroid
    window.getFromiOS = this.getFromiOS
    const ver = versions()
    if (ver.wxMiniProgram || ver.ToutiaoMicroApp) {
      this.proId = this.$route.query.proId
      this.shopId = this.$route.query.shopId
      this.sessionID = this.$route.query.sessionID
      this.mid = this.$route.query.mid
      localStorage.setItem('sessionID', this.sessionID)
      if (this.sessionID.indexOf('GuestSession') > -1) {
        this.bottomState = false
      }
      this.getDetail()
      this.getCartNum()
    } else if (ver.ios) {
      console.log('ios')
    } else {
      console.log('安卓')
    }
  },
  methods: {
    getDetail () {
      const tmpData = {
        proId: this.proId,
        shopId: this.shopId,
        proStatus: 'Y'
      }
      this.$loading.show()
      productShopDetail(tmpData).then(res => {
        if (res.result >= 0) {
          this.$loading.hide()
          const idx = this.idx
          const idx2 = this.idx2
          this.proData = res.resultObject
          this.proDataUnit = this.proData.productDetailSpecifications[idx].proDetailUnits[idx2]
          this.defalutImg = res.resultObject.imageList[0].imgUrl
          const proId = this.proId
          const shopId = this.shopId
          const proName = this.proData.proName
          this.relation = res.resultObject.relation
          // 分享
          wx.miniProgram.postMessage({
            data: {
              proId,
              shopId,
              proName,
              path: `/pages/share/share?proId=${proId}&shopId=${shopId}`
            }
          })
        } else {
          this.$toast(res.message)
        }
      }).catch(err => {
        this.$loading.hide()
        console.error(err)
      })
    },
    getCartNum () {
      const tmpData = {
        shopMId: this.mid
      }
      shoppingCartIcon(tmpData).then(res => {
        this.cartNum = res.resultObject
        if (res.resultObject === '0') {
          this.cartNum = ''
        }
      }).catch(err => {
        console.error(err)
      })
    },
    showPop () {
      this.popStatus = true
    },
    closePop () {
      this.popStatus = false
    },
    selectSpec (index) {
      this.idx = index
      this.idx2 = 0
      const idx = this.idx
      const idx2 = this.idx2
      this.proDataUnit = this.proData.productDetailSpecifications[idx].proDetailUnits[idx2]
    },
    selectUnit (index2) {
      this.idx2 = index2
      const idx = this.idx
      const idx2 = this.idx2
      this.proDataUnit = this.proData.productDetailSpecifications[idx].proDetailUnits[idx2]
    },
    addCart () {
      const index = this.idx
      const index2 = this.idx2
      const data = this.proData
      const data2 = data.productDetailSpecifications[index]
      const data3 = data.productDetailSpecifications[index].proDetailUnits[index2]
      let img
      if (data2.specificationImage) {
        img = data2.specificationImage
      } else {
        img = this.defalutImg
      }
      const tmpData = {
        cartBrandId: data.proBrandId,
        cartBrandName: data.proBrandName,
        cartManufacturer: data.proManufacturersName,
        cartMostRate: data3.ratio,
        cartProductId: data.proId,
        cartProductName: data.proName,
        cartProductImage: img,
        cartProSpeUnitId: data3.proSpecificationUnit,
        cartSpecId: data2.specificationId,
        cartSpecName: data2.specification,
        cartUnitId: data3.unitId,
        cartUnitName: data3.unitName,
        cartSellerMid: this.mid,
        cartShopId: this.shopId,
        cartProductNum: this.inputNum
      }
      if (this.relation === false) {
        this.establishBusiness = true
        return false
      }
      this.$loading.show()
      addShoppingCat(tmpData).then(res => {
        if (res.resultObject.result >= 0) {
          this.$loading.hide()
          this.$toast(res.resultObject.message)
          this.getCartNum()
          setTimeout(() => {
            this.popStatus = false
          }, 500)
        } else {
          this.$toast(res.resultObject.message)
        }
      }).catch(err => {
        this.$loading.hide()
        console.error(err)
      })
    },
    addCartNow () {
      const index = this.idx
      const index2 = this.idx2
      const data = this.proData
      const data2 = data.productDetailSpecifications[index]
      const data3 = data.productDetailSpecifications[index].proDetailUnits[index2]
      let img
      if (data2.specificationImage) {
        img = data2.specificationImage
      } else {
        img = this.defalutImg
      }
      const tmpData = {
        cartBrandId: data.proBrandId,
        cartBrandName: data.proBrandName,
        cartManufacturer: data.proManufacturersName,
        cartMostRate: data3.ratio,
        cartProductId: data.proId,
        cartProductName: data.proName,
        cartProductImage: img,
        cartProSpeUnitId: data3.proSpecificationUnit,
        cartSpecId: data2.specificationId,
        cartSpecName: data2.specification,
        cartUnitId: data3.unitId,
        cartUnitName: data3.unitName,
        cartSellerMid: this.mid,
        cartShopId: this.shopId,
        cartProductNum: this.inputNum
      }
      if (this.relation === false) {
        this.establishBusiness = true
        return false
      }
      this.$loading.show()
      addShoppingCat(tmpData).then(res => {
        if (res.resultObject.result >= 0) {
          this.$loading.hide()
          this.$toast(res.resultObject.message)
          this.getCartNum()
          const ver = versions()
          const speunit = data3.proSpecificationUnit
          const shopId = this.shopId
          const data = {
            speunit,
            shopId
          }
          setTimeout(() => {
            this.popStatus = false
          }, 200)
          if (ver.wxMiniProgram) {
            wx.miniProgram.reLaunch({
              url: `/pages/shopCart/shopCart?speunit=${speunit}`
            })
          } else if (ver.ToutiaoMicroApp) {
            tt.miniProgram.switchTab({
              url: '/pages/shopCart/shopCart'
            })
          } else if (ver.ios) {
            window.webkit.messageHandlers.settlementIOS.postMessage(JSON.stringify(data))
          } else {
            // eslint-disable-next-line no-undef
            submitAndroid.settlement_Android(JSON.stringify(data))
          }
        } else {
          this.$toast(res.resultObject.message)
        }
      }).catch(err => {
        this.$loading.hide()
        console.error(err)
      })
    },
    // 加入常购
    addOften () {
      const tmpData = {
        mchId: this.mid,
        mchProId: this.proId,
        mchShopId: this.shopId
      }
      if (this.relation === false) {
        this.establishBusiness = true
        return false
      }
      this.$loading.show()
      addOften(tmpData).then(res => {
        if (res.result >= 0) {
          this.$loading.hide()
          this.$toast('加入常购成功')
        } else {
          this.$toast(res.message)
        }
      }).catch(err => {
        this.$loading.hide()
        console.error(err)
      })
    },
    // addNum () {
    //   let num = this.inputNum
    //   num++
    //   this.inputNum = num
    // },
    // reduceNum () {
    //   let num = this.inputNum
    //   num--
    //   if (num < 1) {
    //     this.$toast('数量不能小于1')
    //     this.inputNum = 1
    //   } else {
    //     this.inputNum = num
    //   }
    // },
    // onInputNum () {
    //   const num = this.inputNum
    //   if (num < 1) {
    //     this.$toast('数量不能小于1')
    //     this.inputNum = 1
    //   }
    // },
    onLargeImg (e) {
      this.largeImg = true
      this.largeImgUrl = e
    },
    closeImg () {
      this.largeImg = false
    },
    onEstab () {
      const tmpData = {
        shopId: this.shopId,
        mchId: '',
        userId: ''
      }
      this.$loading.show()
      establishBusinessRelationship(tmpData).then(res => {
        if (res.result >= 0) {
          this.$toast(res.message)
          this.establishBusiness = false
          this.getDetail()
        } else {
          this.$toast(res.message)
        }
      }).catch(err => {
        console.error(err)
      })
    },
    // 催货
    shitchExped () {
      this.expeditingStatus = true
    },
    sendExped () {
      const proData = this.proData
      const index = this.idx
      const index2 = this.idx2
      const tmpData = {
        num: this.expeditingNum,
        proSpecUnitId: proData.productDetailSpecifications[index].proDetailUnits[index2].proSpecificationUnit
      }
      this.$loading.show()
      insertShopProUrge(tmpData).then(res => {
        this.$loading.hide()
        this.$toast(res.message)
        this.expeditingStatus = false
      }).catch(err => {
        this.$loading.hide()
        console.error(err)
      })
    },
    toShopCart () {
      const ver = versions()
      if (ver.wxMiniProgram) {
        wx.miniProgram.switchTab({
          url: '/pages/shopCart/shopCart'
        })
      } else if (ver.ToutiaoMicroApp) {
        tt.miniProgram.switchTab({
          url: '/pages/shopCart/shopCart'
        })
      } else if (ver.ios) {
        window.webkit.messageHandlers.shopCart_iOS.postMessage('123')
      } else {
        // eslint-disable-next-line no-undef
        submitAndroid.shopCart_Android('123')
      }
    },
    // 接收安卓和IOS
    getFromAndroid (res) {
      const data = JSON.parse(res)
      this.proId = data.proId
      this.shopId = data.shopId
      this.sessionID = data.sessionID
      this.mid = data.mid
      localStorage.setItem('sessionID', this.sessionID)
      if (this.sessionID.indexOf('GuestSession') > -1) {
        this.bottomState = false
      }
      this.getDetail()
      this.getCartNum()
    },
    getFromiOS (res) {
      const data = JSON.parse(res)
      this.proId = data.proId
      this.shopId = data.shopId
      this.sessionID = data.sessionID
      this.mid = data.mid
      localStorage.setItem('sessionID', this.sessionID)
      if (this.sessionID.indexOf('GuestSession') > -1) {
        this.bottomState = false
      }
      this.getDetail()
      this.getCartNum()
    }
  }
}
</script>

<style scoped lang="scss">
.contain {
  background-color: #F3F3F3;
  padding-bottom: 81px;
}
.topnav {
  ::v-deep .van-nav-bar__left .van-icon{
    color: #000;
  }
  ::v-deep .van-nav-bar__right .van-icon{
    color: #000;
  }
}
.topswipe {
  .van-swipe {
    height: 375px;
  }
}
.ta {
  background-color: #fff;
  padding: 10px;
  border-bottom: 1px solid #f5f5f5;
  .ta-1 {
    font-size: 12px;
    margin-top: 6px;
  }
  .ta-2 {
    font-size: 16px;
    font-weight: bold;
  }
  .ta-3 {
    font-size: 12px;
    color: #D7A963;
    margin-top: 6px;
  }
}
.tb {
  background-color: #fff;
  padding: 10px;
  border-bottom: 1px solid #f5f5f5;
  .tb-1 {
    font-size: 14px;
    font-weight: bold;
  }
  .tb-2 {
    display: flex;
    flex-wrap: wrap;
    .item2 {
      max-width: 110px;
      font-size: 12px;
      border: 1px solid #F3F3F3;
      border-radius: 3px;
      background-color: #F3F3F3;
      display: flex;
      margin: 10px 10px 0 0;
      padding: 3px;
      cursor: pointer;
      align-items: center;
    }
    .item2:nth-child(3n) {
      margin-right: 0;
    }
    .active {
      background-color: #fff;
      border: 1px solid #E40404;
      border-radius: 3px;
      color: #E40404;
    }
  }
  .tb-3 {
    display: flex;
    flex-wrap: wrap;
    .item3 {
      font-size: 12px;
      border: 1px solid #F3F3F3;
      border-radius: 3px;
      background-color: #F3F3F3;
      margin: 10px 10px 0 0;
      display: inline-block;
      padding: 4px;
      word-break: break-all;
    }
    .active {
      background-color: #fff;
      border: 1px solid #E40404;
      border-radius: 3px;
      color: #E40404;
    }
  }
}
.tc {
  background-color: #fff;
  margin-top: 10px;
  padding: 10px;
  border-bottom: 1px solid #f5f5f5;
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  .tc-1 {
    .tci:not(:first-child) {
      margin-top: 10px;
    }
    .item {
      color: #E40404;
      padding: 3px 5px;
      border: 1px solid #E40404;
      border-radius: 3px;
    }
  }
}
.td {
  margin-top: 10px;
  background-color: #fff;
  padding: 10px;
  .td-1 {
    font-size: 14px;
    font-weight: bold;
  }
  .td-2 {
    margin-top: 10px;
    .td2-item {
      display: flex;
      background-color: #F5F5F5;
      font-size: 12px;
      .iteml {
        flex: 0 0 30%;
        padding: 10px;
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
      }
      .itemr {
        flex: 1;
        padding: 10px;
        border-bottom: 2px solid #fff;
      }
    }
  }
}
.te {
  background-color: #fff;
  max-width: 100%;
  ::v-deep img {
    max-width: 100%;
  }
}
.hot {
  margin-top: 10px;
  background-color: #fff;
  .ha {
    text-align: center;
    padding: 10px 0px;
  }
  .hb {
    .flexGoods {
      display: flex;
      flex-wrap: wrap;
      padding: 0 8px;
    }
  }
}
.bottom {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background-color: #fff;
  padding: 8px 10px 20px 10px;
  .bottom-item {
    font-size: 12px;
    text-align: center;
    width: 20%;
  }
  .bottom-item2 {
    text-align: center;
    flex: 1;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .bottom-item3 {
    flex: 1;
    line-height: 44px;
    font-weight: bold;
  }
}
.popup {
  .goodsitem-contain {
    padding: 10px;
    font-size: 13px;
  }
  .goodsitem-contain .a {
    display: flex;
    margin-top: 15px;
    border-bottom: 1px solid #f3f3f3;
    padding-bottom:10px;
  }
  .goodsitem-contain .a .l {
    width: 30%;
  }
  .goodsitem-contain .a .r {
    width: 68%;
  }
  .goodsitem-contain .b {
    padding: 10px 0;
  }
  .goodsitem-contain .b .bs {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .goodsitem-contain .b .bs .bsinput {
    width: 34px;
    text-align: center;
    border: none;
  }
  .goodsitem-contain .b .tag {
    background-color: #F5F5F5;
    border-radius: 4px;
    padding: 9px 10px;
    margin-right: 10px;
    border: 1px solid #F5F5F5;
  }
  .goodsitem-contain .b .selecttag {
    background-color: #fff;
    color: #E40404;
    border: 1px solid #E40404;
    border-radius: 3px;
  }
  .goodsitem-contain .b .van-button__text{
    //font-size: 16px;
    //font-weight: bold;
  }
  .goodsitem-contain .b .bd {
    margin-top: 20px;
  }
  .goodsitem-contain .b .be {
    margin: 20px 0;
  }
  .goodsitem-contain .be .btn {
    color: #E40404;
    background-color: #F0EFEF;
  }
}
.visibility {
  visibility: hidden;
}
.height15 {
  height: 15px;
}
.jf {
  background-color: #fff;
  padding: 6px 10px;
}
.jf2 {
  background-color: #fff;
  padding: 6px 0px;
}
.youhui {
  border: 1px solid #E40404;
  border-radius: 3px;
  font-size: 12px;
  color: #E40404;
  padding: 1px 2px;
  white-space: normal;
}
</style>
